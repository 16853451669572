<template>
    <div>
        <div class="cos_Bg" style="background-image: url(img/cosmos/cos_bg.png);">
            <div class="cos_back" style="background-image: url(img/cosmos/cos_back.png);" @click="goback"></div>
            <div class="intro_box">
                <div class="cos_tit" style="background-image: url(img/cosmos/title1.png);">
                    <span>拆出记录</span>
                </div>
                <div class="record_txt">
                    <div class="display_flex justify-content_flex-center align-items_center margin-bottom">
                        <div class="luck_nav margin-lr"
                            :style="{ backgroundImage: 'url(img/cosmos/' + (navActive == index ? 'luck_active' : 'luck_costom') + '.png)' }"
                            v-for="(item, index) in nav" :key="index" @click="changeNav(index)">{{ item.gift_name }}</div>
                    </div>
                    <div class="record_totalNum">今日开启：{{ total_num }}次</div>
                    <div class="record_box">
                        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData">
                            <div class="record_item display_flex justify-content_flex-justify align-items_center"
                                v-for="(item, index) in indexData" :key="index">
                                <div class="display_flex align-items_center">
                                    <div class="rod_gift display_flex justify-content_flex-center align-items_center">
                                        <van-image class="rod_image" fit="contain" :src="item.gift_url" />
                                    </div>
                                    <div class="margin-left-sm">
                                        <p class="record_name">{{ item.gift_name }}<span class="margin-left-sm">x{{ item.num
                                        }}</span></p>
                                        <p class="record_time">{{ item.created_at }}</p>
                                    </div>
                                </div>
                                <div class="record_num" style="background-image: url(img/cosmos/luck_num.png);">
                                    {{ item.total_num }}次</div>
                            </div>
                        </van-list>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/cosmos.css"
import { openBoxRecord } from "@/api/ranking";
export default {
    name: 'recordList',
    data() {
        return {
            navActive: 0,
            nav: [{ gift_name: '魔法星球', id: 1 }, { gift_name: '快乐星球', id: 2 }],
            gift: 1,
            loading: false,
            finished: false,
            indexData: [],
            noData: false,
            page: 1,
            row: 10,
            total_num: '0'
        }
    },
    created() {
        // this.fetchData()
    },
    // mounted() {

    //     window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动
    // },
    // destroyed() {
    //     window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
    // },
    methods: {
        // Scrollbottom() {
        //     let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //     let clientHeight = document.documentElement.clientHeight;
        //     let scrollHeight = document.documentElement.scrollHeight;
        //     if (scrollTop + clientHeight >= scrollHeight) {

        //         this.fetchData()
        //         // this.pageNo++
        //         // console.log(this.pageNo);
        //         // this.fetchData()

        //     }
        // },
        fetchData() {
            if (this.nav.length == 0) {
                return false
            }
            let eggs_id = this.nav[this.navActive].id
            let param = {
                page: this.page,
                rows: this.row,
                eggs_id: eggs_id
            }
            openBoxRecord(param).then(response => {
                this.indexData = this.indexData.concat(response.data.list);
                this.loading = false;
                this.total_num = response.data.today_total_num
                if (response.data.list.length < this.row) {
                    this.finished = true;
                }
                if (this.page == 1 && response.data.list.length == 0) {
                    this.noData = true
                } else {
                    this.noData = false
                }
                this.page = this.page + 1
            },
                error => {
                    this.loading = false;
                    this.finished = true;
                    this.$toast.fail(error)
                }).catch((error) => {
                    this.loading = false;
                    this.finished = true;
                    this.$toast.fail(error)
                })
        },
        changeNav(e) {
            this.navActive = e
            this.loading = true;
            this.finished = false;
            this.indexData = [];
            this.page = 1
            this.fetchData();
        },
        goback() {
            this.$router.go(-1)
        }
    }
}
</script>
<style>
.van-list__finished-text {
    color: rgba(255, 255, 255, .7);
}
</style>
